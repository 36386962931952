import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { getPageProductCate } from "../api/ProductCategoryApi.js";
import Noimg from "../images/no_img.jpg";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel, { consts } from "react-elastic-carousel";
import { dataList } from "api/SkeletonData.js";
import no_data from "images/no_data.png";
import { getBranchId } from "utility/HeaderConfig.js";

const CardContainer = tw.div`mt-4 w-full`;
const Card = tw(
  motion.a
)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded `}
`;

const Heading = tw.h1`text-center  block text-black text-4xl m-0 pb-2`;

export const NavLink = tw.button`block no-underline text-black font-semibold m-auto`;

export default () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(10);
  const history = useHistory();
  useEffect(() => {
    getCategoryList();
    //eslint-disable-next-line
  }, []);

  const getCategoryList = () => {
    setLoading(true);
    setSize(size + 6);
    getPageProductCate(size, { visibilityMode: "WEB"}).then((data) => {
      setCategoryList(data.response.content);
      setLoading(false);
    });
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          className="bi bi-arrow-left-circle"
          tw="sm:text-4xl text-3xl text-custom-100 hover:text-black"
        ></i>
      ) : (
        <i
          className="bi bi-arrow-right-circle"
          tw="sm:text-4xl text-3xl text-custom-100 hover:text-black"
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  return (
    <div className="py-2 py-sm-5">
      <div className="container py-2 text-center ">
        <div tw="flex justify-center">
          <Heading>Shop By Category</Heading>
        </div>
        <small>
          Browse through your favourite categories. We've got them all!
        </small>
      </div>
      <div className="container">
        {categoryList.length === 0 && !loading ? (
          <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
        ) : (
          <Carousel
            itemPadding={[0, 10]}
            itemsToShow={5}
            outerSpacing={0}
            showEmptySlots
            breakPoints={breakPoints}
            renderArrow={myArrow}
          >
            {categoryList.map((categoryItem, index) => (
              <div className="w-100" key={index}>
                <CardContainer
                  onClick={() =>
                    history.push("/ProductList", {
                      category: categoryItem,
                    })
                  }
                  tw="cursor-pointer relative"
                >
                  <Card className="group position-relative ">
                    <div className="overflow-hidden">
                      <CardImageContainer
                        className="group"
                        tw="h-64 border border-gray-300 rounded-t-lg flex justify-center w-full  transform transition duration-500  group-hover:(scale-125 rotate-12)"
                        imageSrc={
                          categoryItem.categoryImage === "Image_Not_Available" || categoryItem.categoryImage === "-" || categoryItem.categoryImage === null
                            ? Noimg
                            : categoryItem.categoryImage
                        }
                      ></CardImageContainer>
                    </div>
                    <div tw="text-center  border border-gray-300  font-semibold bg-white px-5 py-3 text-black text-xs uppercase">
                      <NavLink className="mb-2">
                        {categoryItem.categoryName}
                      </NavLink>
                      <span tw="text-custom-100">
                        Explore{" "}
                        <i className="mx-1 bi bi-arrow-up-right-circle-fill"></i>
                      </span>
                    </div>
                  </Card>
                </CardContainer>
              </div>
            ))}
            {loading &&
              dataList.map((data ,index) => (
                <div className="w-100" key={index}>
                  <CardContainer>
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                    >
                      <Skeleton
                        className="group"
                        tw="h-64   mb-1 border border-gray-300 rounded-lg flex justify-center w-full "
                      />
                      <Skeleton tw="block no-underline text-black font-semibold h-12" />
                    </Card>
                  </CardContainer>
                </div>
              ))}
          </Carousel>
        )}
        <div className="d-flex justify-content-center py-0 sm:py-3">
          <div
            type="button"
            tw="transition-all duration-700 mt-4 border border-custom-100 hover:text-white hover:bg-custom-100 px-20 py-2 text-sm"
            onClick={() => history.push("ProductList")}
          >
            {loading ? "Loading ..." : "View More"}
          </div>
        </div>
      </div>
    </div>
  );
};
