import tw from "twin.macro";
import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";

const ColumnHeading = tw.h5`uppercase font-bold tracking-widest m-0`;
const LinkList = tw.ul`my-5 font-medium p-0`;
const LinkListItem = tw.li`mt-3`;
const SocialLinksContainer = tw.div`flex items-center mb-5 duration-500 `;

function Footer({ branch, configImages }) {
  const [gatwayIcon, setgatwayIcon] = useState([]);
  const [qrCode, setQrcode] = useState([]);
  useEffect(() => {
    getBanner();
    //eslint-disable-next-line
  }, []);

  const getBanner = () => {
    configImages.PGIMAGES &&
      configImages.PGIMAGES.forEach((data) => {
        if (data.key === "paymentGatway") {
          setgatwayIcon(data.value);
        }
      });

    configImages.MOBILE_APP_QR &&
      configImages.MOBILE_APP_QR.forEach((data) => {
        if (data.key === "qrCode") {
          setQrcode(data.value);
        }
      });
  };

  return (
    <>
      <footer className="ptSans" tw=" bg-cover bg-no-repeat">
        <div className="container">
          <div className="row m-auto" tw="py-16">
            <div className="col-6 col-lg-3 col-md-4">
              <ColumnHeading>Policies</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link
                    to="TermsConditions"
                    tw="no-underline duration-500 hocus:px-1 pb-1 text-gray-900 hover:text-custom-100"
                  >
                    Terms and Conditions
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="PrivacyPolicy"
                    tw="no-underline hocus:px-1 duration-500  pb-1 text-gray-900 hover:text-custom-100"
                  >
                    Privacy Policy
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="ShippingPolicy"
                    tw="no-underline hocus:px-1 duration-500  pb-1 text-gray-900 hover:text-custom-100"
                  >
                    Shipping Policy
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="RefundPolicy"
                    tw="no-underline hocus:px-1 duration-500  pb-1 text-gray-900 hover:text-custom-100"
                  >
                    Refund Policy
                  </Link>
                </LinkListItem>
              </LinkList>
            </div>
            {/* <div className="col-6 col-lg-3 col-md-4">
              <ColumnHeading>Quick Links</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Link
                    to="/"
                    tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                  >
                    Home
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="About"
                    tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                  >
                    About
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="ProductList"
                    tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                  >
                    Product
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="NewArrivals"
                    tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                  >
                    New Arrivals
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <Link
                    to="MostLiked"
                    tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                  >
                    Most Liked{" "}
                  </Link>
                </LinkListItem>
              </LinkList>
            </div> */}

            <div className="col-6 col-lg-3 col-md-4">
              <ColumnHeading>Contact Us</ColumnHeading>
              <LinkList>
                <Link
                  to="#"
                  tw="no-underline hocus:px-1 pb-1 duration-500  text-gray-900 hover:text-custom-100"
                >
                  <SocialLinksContainer>
                    <i className="bi bi-pin-map" tw="text-2xl text-black"></i>
                    <div tw="ml-4 mb-0">
                      <h4>MKM Thangamaligai LLP</h4>
                    <p tw="m-0">{Object.keys(branch).length > 0 && branch.branchAddress.address1 + ' ' + branch.branchAddress.address2
                    +' ' + branch.branchAddress.talukName+' - ' + branch.branchAddress.pincodeNumber+', ' + branch.branchAddress.stateName+' ' + branch.branchAddress.countryName}</p>
                    <p>{ }</p>
                    
                    </div>
                  </SocialLinksContainer>
                </Link>
                <SocialLinksContainer>
                  <i className="bi bi-telephone" tw="text-2xl text-black"></i>
                  <p tw="ml-4 mb-0">+91 {branch.mobile}</p>
                </SocialLinksContainer>
                <SocialLinksContainer>
                  <i
                    className="bi bi-envelope-paper"
                    tw="text-2xl text-black"
                  ></i>
                  <a
                    href="mailto:mkmthangamaligai@gmail.com"
                    tw="ml-4 mb-0 no-underline text-gray-900 hover:text-custom-100"
                  >
                    {branch.email}
                  </a>
                </SocialLinksContainer>
              </LinkList>
            </div>

            <div className="col-6 col-lg-6 col-md-6">

            <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29057.562584759613!2d77.71270941712888!3d8.71932635343139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b041178cf39492d%3A0x704f58f7156592e7!2sMKM%20Jewellery!5e0!3m2!1sen!2sin!4v1704110946052!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      // style="border:0;"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="branch one"
                    ></iframe>
            </div>
            

            <div className="col-6 col-lg-3 col-md-4">
              <a
                href="https://play.google.com/store/apps/dev?id=6531507115281063048"
                target="_blank"
                rel="noreferrer" 
              >
                {/* <img src={qrCode} className="w-100" alt="QR Code"/> */}
              </a>
            </div>
          </div>
          <div className="row py-4  border-top align-items-cener">
            <div className="col-sm-6">
              {/* <img
                src={gatwayIcon}
                alt="payment_option"
                className="mx-auto mx-sm-0"
              /> */}
            </div>
            <div className="col-sm-6">
              <p className="mt-3 mb-0 m-sm-0  text-sm-end text-center">
                Copyright © 2024 {branch.storeName}. All Rights Reserved.
                Designed By{" "}
                <a
                  tw="no-underline text-custom-100"
                  href="https://rasiinfotech.com/"
                >
                  Rasi Infotech
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default memo(Footer);
