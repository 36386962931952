import tw from "twin.macro";
import Carousel from "./components/Carousel.js";
import ProductCategory from "./components/ProductCategory.js";
import GenderBased from "./components/GenderBasedProduct.js";
import ProductType from "./components/ProductType";
import ArrivalsLiked from "components/ArrivalsLiked.js";
import SocialMedia from "components/SocialMedia.js";
import AppQRCODE from "./images/app-qrcode.png";
import googleImg from "./images/google-play-6647242_1280.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const Container = tw.div`relative font-display overflow-hidden`;
const retailShop = "https://images.mkmthangamaligai.com/shop.jpeg";
export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */



  return (
    <Container>
      <Carousel />
      <div className="container" tw="lg:py-16 py-10 ">
        <div className="row align-items-center flex-column-reverse flex-lg-row py-2">
          <div className="col-md-12 col-lg-6">
            <h1 tw="text-5xl my-5 lg:mt-2">WHO WE ARE!!</h1>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
                MKM Jewellers, a Retail Jewellery Showroom in Tirunelveli was established in 1996. MKM Jewellers has the distinction of being "One of the Best Retail Jewellery in South Tamilnadu".
                MKM Thangamaligai LLP today is recognized as a high
                quality gold manufacturer with an extensive jewellery product
                range to meet the expectations of every customer.
              </p>
              <p tw="text-zinc-300 ">
                MKM Thangamaligai LLP was started its operations
                with a next-generation luxurious showroom in Tirunelveli in the year
                1996.  We are now the
                jewellery destination of choice for every occasion, from a
                woman’s everyday wear to delicately crafted pieces for the
                biggest day of her life. Our stores are staffed with highly
                trained personnel who ensure that every customer leaves a{" "}
                MKM Thangamaligai LLP completely satisfied.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <img
              src={retailShop}
              alt="About-Img"
              tw="absolute right-0 top-0 -z-10 hidden lg:block w-5/12"
            />

          </div>
        </div>
        <div className="row py-5 mt-lg-2 align-items-center">
          <div className="col-md-5 col-lg-3">
            <img
              src={AppQRCODE}
              alt="App QR Code"
              className="w-100"
              tw="rounded-xl"
            />
          </div>
          <div className="col-md-4 col-lg-4">

            <div className="ptSans text-base text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.ri.zinger.mkmthangamaligai"
                target="_blank"
                rel="noreferrer"
              >

                <h3 tw="text-zinc-300 ">
                  Download Now
                </h3>
                <img
                  src={googleImg}
                  alt="App QR Code"
                  className="w-50"
                  tw="rounded-xl"
                  style={{ margin: "0 auto" }}
                />
              </a>
              <h3>The app for all your <br /> MKM Thangamaligai LLP <br />Jewellery process.</h3>

            </div>

          </div>
        </div>


      </div>

      {/* <ProductType />
      <ProductCategory />
      <ArrivalsLiked />
      <GenderBased /> */}
      <SocialMedia />
    </Container>
  );
};
