import { useEffect, useState } from "react";
import "twin.macro";
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";

const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

export default ({ configImages , branch}) => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const getBanner = () => {
      configImages &&
        configImages.E_COMMERCE_BANNERS.forEach((data) => {
          if (data.key === "refundPolicy") {
            setBanner(data.value);
          }
        });
    };
    getBanner();
    //eslint-disable-next-line
  }, []);

  let  Site_Url = "https://mkmthangamaligai.com";


  const list = [
    {
      title: "RETURN & EXCHANGE POLICY",
      description: `${branch.storeName} strives hard in ensuring the satisfaction of every customer who have purchased or ordered with it. The process of return and exchange of the product purchased online via ${Site_Url} is designed as simple and user friendly as possible.`,
      show: "show",
    },
    {
      title: "i) Terms for Return before Delivery",
      description: `If a customer prefers to return the product ordered or purchased before delivery for some valid reason, ${branch.storeName} provides them with an option to do so and this shall be considered as ’Cancellation before Delivery’. Check cancellation terms section.`,
      show: "",
    },
    {
      title: "ii) Terms for Return on defects",
      description: `Customer may return the delivered product if found to be defective upon arrival at customer location. Customer shall exercise this privilege only for genuine faults and ${branch.storeName} hopes the facility is not misused. You are permitted to return jewels housing genuine wears, manufacturing defects, missing stones, cracks on the stones provided the jewel is unused. In such a case it has to be reported to ${Site_Url} as ’Defective upon delivery’, on the date of delivery itself. Return of jewellery is also reasonable and acceptable in cases where the jewel delivered to you is different from what was ordered if it remains unused. ${branch.storeName} reserves every right in determining if the product delivered can be considered as ’Defective upon delivery’.`,
      description2: `If the customer tampered the jewel deliberately with or without the intention of usurping financial benefits, legal action will be taken under all applicable laws for cheating, theft, forgery etc., for the damage or defamation caused to the company. In such an event, ${branch.storeName} apart from initiating legal actions against such customer shall also penalize by not refunding the amount paid towards the purchase of jewel. Users, beware of the fact that ${branch.storeName} maintains every record- visual, factual as well as legal information about the product that is shipped. Care should be taken while returning through courier. The product should be returned in same condition as delivered by ${branch.storeName}.`,
      show: "",
    },
    {
      title: "iii) Terms for Return on Dissatisfaction - Limited Duration",
      description: `In case, the customer is not satisfied with the product after receiving they can return the product within 15 days from the delivered date. Return policy is subject to the following conditions:
  
      a) The product should be returned in the same condition as delivered by ${branch.storeName}.
      
      b) Product showing sign of wear or engraved, altered, resized or damaged may not be accepted and may be returned to the customer itself.
      
      c) Products that are designed based on Customer’s specification or customized jewels or Gold coins can be exchanged only at the showrooms of ${branch.storeName} binding by the terms stated under ’Lifetime Exchange’.
      
      d) Once the return is confirmed at the call center, ${branch.storeName} will send a packaging cover through the courier agent, which has to be used to return the product. ’Jewel to be returned’ must be properly packed as per the associated logistics company’s requirement.
      
      e) The item has to be packed in front of the courier agent.`,
      description2: `f) Product invoice provided by ${branch.storeName} must be sent along with the ’Jewel to be returned’ while returning. Else return process shall be void.
  
      g) It is presumed that users are aware about Jewellery and the right way of handling them before purchase and ${branch.storeName} shall not take responsibility for any wear or damage caused by inappropriate handling or improper placement. Return or refund or exchange of such products of such a case shall not be entertained by ${branch.storeName}.
      
      h) Once ${branch.storeName} receives the jewel genuinely as stated above, the refund of the payment shall be promptly initiated within 15 days without deduction of shipping, processing fee and bank transaction charges through internet banking or cheque to the bank account that was used for the purchase.`,
      show: "",
    },
    {
      title: "v) Terms for Exchange",
      description:
        `In case you wish to exchange your item with in 15 days from the date of purchase for an alternative product, we suggest that you return the product for a credit and purchase the new product separately.For any exchange of certain products, user may confirm it with the support team of ${branch.storeName}.${Site_Url} may offer a complimentary free return shipping for exchange of certain products. User may confirm it with the support team of ${branch.storeName}.Customer may return the product for exchange within 15 days for full invoice value. User shall bear the varied price of the new product purchased if it’s current invoice value is greater than previously generated invoice value, of the returned item(s), and if the current invoice value is lesser than the previous invoice value ${branch.storeName} shall process the difference amount to the source bank account of the customer.Customized jewels and Gold coins are not eligible for return shipping and shall be exchanged only at our showrooms. For all transactions such as return, refund and exchange, price is determined by ${branch.storeName}. Products that shows any sign of wear, tear, alter or tamper shall not be eligible for exchange or refund. All return or refund or money back shall be paid to the customer through cheque or internet banking within 15 working days to the user’s purchased account after deducting whatever transaction charges that are involved. In case of alteration of customized jewels, user shall bear the shipping (includes return shipping), customization or repair charges that may apply.`,
      show: "",
    },
    {
      title: "iv) Terms for Gifts",
      description: `Return of ’gifts’ (as defined in Annotation section) shall follow the ’Return on dissatisfaction - Limited Duration’, ’Return on defects’, ’Lifetime Exchange’, provided the beneficiary must bring the photo identity card recognized as photo Identity proof by Government of India, to prove the genuinity as the beneficiary considered by the customer who purchased the product.`,
      show: "",
    },
    {
      title: "Repairs",
      description: `In case the jewels bought needs repair, it has to be given in any of the showrooms of ${branch.storeName}. The customer must produce the invoice of the purchase. Customer must bear the repair charges as informed by ${branch.storeName}.    `,
      show: "",
    },
    {
      title: "Insurance",
      description: `Insurance is provided during the transit of every purchase. The insurance will be valid when the merchandise is dispatched and will be void the moment following delivery to the respective customer or beneficiary i.e., the insurance becomes void or ceases once the receipt for delivery is acknowledged.`,
      show: "",
    },
  ];



  return (
    <div
      tw="relative font-display overflow-hidden"
      className="custom_background"
    >
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Refund Policy" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}

      <Heading>RETURN & EXCHANGE POLICY</Heading>
      <div className="container py-2 py-sm-5">
        <div className="accordion" id="accordionExample">
          {list.map((data, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button text-uppercase`}
                  //  ${
                  //   data.show === "" ? "collapsed" : "show"
                  // }`}
                  type="button"
                  data-bs-toggle="collapse"
                  // data-bs-target={`#one${index}`}
                >
                  {data.title}
                </button>
              </h2>
              <div
                id={`one${index}`}
                className={`accordion-collapse collapse ${
                  // data.show === "" ? "collapsed" :
                  "show"
                } ptSans`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>{data.description}</p>
                  <p>{data.description2}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
