import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TabControl = styled.div`
  ${tw`rounded p-6  cursor-pointer border-dashed border-2 border-custom-100 shadow-2xl`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;
export default function   SocialMedia() {
  return (
    <div className="section social_media">
      <div className="container  p-0">
        <div className="row align-items-center py-5 justify-content-center">
          <div className="col-sm-6  text-center text-white">
            <h1 className="fw-bold" tw="md:m-0 my-5 uppercase">
              Connect With Us
            </h1>
            <p className=" ptSans">
              We are always available to guide you at your convenience
            </p>
            <div className="col-sm-6 py-2 m-auto  px-sm-0 px-2">            
              <a 
               href={`https://api.whatsapp.com/send?phone="+918870335151"&text=Hii`}
              tw="no-underline" target="_black" >
              <div className="card o-hidden border-0 overflow-hidden">
                <TabControl className="card-body custom_background">
                    <div className="media static-top-widget d-flex align-items-center justify-content-around text-center">
                      <div className="align-self-center text-center">
                        <i
                          className="bi bi-whatsapp"
                          tw="text-5xl text-custom-100"
                        ></i>
                      </div>
                      <div className="media-body pl-3 text-custom-100 ptSans">
                        <span tw="text-custom-100">Connect On</span>
                        <h4
                          className="mb-1 text-uppercase"
                          tw="text-2xl font-semibold tracking-widest text-custom-100"
                        >
                          WhatsApp
                        </h4>

                        <i
                          className="bi bi-whatsapp icon-bg"
                          tw="text-custom-100"
                        ></i>
                      </div>
                    </div>                  
                </TabControl>
              </div>
              </a>
            </div>
            {/* <div className="col-sm-6 py-2 m-auto px-sm-0 px-2">
              <Link to="/BookAnAppointment" tw="no-underline">
              <div className="card o-hidden border-0 overflow-hidden">
                <TabControl className="card-body custom_background">
                  <div className="media static-top-widget d-flex align-items-center justify-content-around text-center">
                    <div className="align-self-center text-center">
                      <i
                        className="bi bi-camera-video"
                        tw="text-5xl text-custom-100"
                      ></i>
                    </div>
                    <div className="media-body pl-3 text-custom-100 ptSans">
                      <span tw="text-custom-100 ">Schedule a</span>
                      <h4
                        className="mb-1 text-uppercase"
                        tw="text-2xl font-semibold tracking-widest text-custom-100"
                      >
                        Video Call
                      </h4>

                      <i
                        className="bi bi-camera-video icon-bg"
                        tw="text-custom-100"
                      ></i>
                    </div>
                  </div>
                </TabControl>
              </div>
              </Link>
            </div> */}
          </div>
          <div className="col-sm-6  text-center">
            {/* <img src={vector} alt="model-images" className="w-100 pt-4 " /> */}
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/profile.php?id=100067469193953&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=3334456943453428"
              height="600"
              tw="w-full sm:w-5/6"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              className="border-sm border-sm-light border-sm-3 rounded shadow-sm-lg p-2 m-auto"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              title="social media"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
