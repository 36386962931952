import React, { useState } from 'react';
import CCAvUtil from './ccavutil'; // Import CCAvUtil from ccavutil.js


const CCAvRequestHandler = () => {
  const [formBody, setFormBody] = useState('');
  
  const initiateTransaction = async () => {
    const merchantId = '1207638'; // Replace with your actual merchant ID

    // Use CCAvUtil to encrypt the request
    const ccavUtil = new CCAvUtil(); // Instantiate CCAvUtil
    const encRequest = ccavUtil.encrypt({ merchant_id: merchantId }); // Call encrypt() method

    const accessCode = 'AVMB11JG46BU36BMUB'; // Replace with your actual access code

    // Build the form body with the encrypted request
    const newFormBody = `
      <html>
        <head>
          <title>Sub-merchant checkout page</title>
          <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
        </head>
        <body>
          <center>
            <!-- width required minimum 482px -->
            <iframe
              width="482"
              height="500"
              scrolling="No"
              frameborder="0"
              id="paymentFrame"
              src="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${merchantId}&encRequest=${encRequest}&access_code=${accessCode}"
            ></iframe>
          </center>
          <script type="text/javascript">
            $(document).ready(function() {
              $("iframe#paymentFrame").load(function() {
                window.addEventListener(
                  "message",
                  function(e) {
                    $("#paymentFrame").css("height", e.data["newHeight"] + "px");
                  },
                  false
                );
              });
            });
          </script>
        </body>
      </html>
    `;

    setFormBody(newFormBody);
  };

  return (
    <div>
      {/* Render the form body */}
      {formBody ? (
        <div dangerouslySetInnerHTML={{ __html: formBody }}></div>
      ) : (
        <button onClick={initiateTransaction}>Pay Now</button>
      )}
    </div>
  );
};

export default CCAvRequestHandler;
