import { createContext,  useReducer } from "react";
import { getAddToCart, getBranchId, getCustomerId } from "utility/HeaderConfig";


export const CartContext = createContext();

//user Reducer

const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return { ...state, data: action.payload };
    case "LOGIN":
      return { ...state, login: action.payload };
    case "MODAL":
      return { ...state, modal: action.payload };
    case "BRANCHID": 
      return {...state, branchId: action.payload}
    default:
  }
};

export const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    data: getAddToCart() && JSON.parse(getAddToCart()),
    login: getCustomerId(),
    branchId: getBranchId(),
    modal: false,
  });
  
  // const [configImages, setConfigImages] = useState([]);
  // useEffect(() => {
  //   banners();
  // }, []);

  // const banners = () => {
  //   let filter = "e_comm";
  //   ECommerce(filter).then((data) => {
  //     let SOCIALMEDIA = JSON.parse(data.response.moduleField);
  //     setConfigImages(SOCIALMEDIA);
  //   });
  // };

  return (
    <CartContext.Provider value={{ ...state, dispatch}}>
      {children}
    </CartContext.Provider>
  );
};
