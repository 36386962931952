import React, { useEffect, useState } from "react";
import { getPageProductType } from "api/ProductTypeApi";
import tw from "twin.macro";
import gold from "../images/productType/GOLD.png";
import silver from "../images/productType/SILVER.png";
import diamond from "../images/productType/DIAMOND.png";
import gift_articles from "../images/productType/GIFT_ARTICLES.png";
import silver_articles from "../images/productType/SILVER_ARTICLES.png";
import coin from "../images/productType/COIN.png";
import platinum from "../images/productType/PLATINUM.png";
import { useHistory } from "react-router-dom";
import { dataList } from "api/SkeletonData.js";
import Skeleton from "react-loading-skeleton";


const Container = tw.div`relative`;
const Heading = tw.h1`text-center  block text-black  text-4xl m-0`;

export default function ProductType() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const getProductType = () => {
      setLoading(true);
      getPageProductType({}).then((data) => {
        let response = data.response.content;
        let filterData = response.filter((data) => {
          if (data.metaProductCategoryList.length > 0) {
            const TypeName = data.name.toLowerCase().trim();
            return (
              TypeName === "gold" ||
              TypeName === "silver" ||
              TypeName === "silver articles" ||
              TypeName === "gift articles" ||
              TypeName === "diamond" ||
              TypeName === "platinum"
            );
          } else {
            return false;
          }
        });
        setLoading(false);
        setData(filterData);
      });
    };
    getProductType();
  }, []);

  return (
    <Container className="container py-3 py-sm-2">
      <div className="container py-sm-3 text-center mb-2 mb-sm-4">
        <div tw="flex justify-center py-2">
          <Heading>Shop By Type</Heading>
        </div>
        <small>Explore our latest designs curated just for you!</small>
      </div>
      <div className="py-sm-0 py-3 ttaboutbanner1  left-to-right hb-animate-element hb-in-viewport">
        <div className="row">
          {loading &&
            dataList.slice(0, 6).map((data , index) => (
              <div className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3" key={index}>
                  <Skeleton
                        className="group"
                        tw="h-72  mb-1 border border-gray-300 rounded-lg flex justify-center w-full "
                      />
              </div>
            ))}
          {data?.map((data) => {
            if (data.name.toLowerCase() === "gold")
              return (
                <div className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3" key={1}>
                  <div
                    className="ttabout-img"
                    tw="relative p-0"
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={gold}
                        alt="cms-01.jpg"
                        className="w-100 rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "silver")
              return (
                <div key={2} className="block1 ttaboutbanner col-6  col-sm-6  col-lg-4  position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0"
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={silver}
                        alt="cms-01.jpg"
                        className="w-100 rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "diamond")
              return (
                <div key={3} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0 "
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={diamond}
                        alt="cms-01.jpg"
                        className="w-full rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "silver articles")
              return (
                <div key={4} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4  position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0 "
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={silver_articles}
                        alt="cms-01.jpg"
                        className="w-full rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "gift articles")
              return (
                <div key={5} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0 "
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={gift_articles}
                        alt="cms-01.jpg"
                        className="w-full rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "coin")
              return (
                <div key={6} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4  position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0 "
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={coin}
                        alt="cms-01.jpg"
                        className="w-full rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
            if (data.name.toLowerCase() === "platinum")
              return (
                <div key={6} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3">
                  <div
                    className="ttabout-img"
                    tw="relative p-0"
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                      <img
                        src={platinum}
                        alt="cms-01.jpg"
                        className="w-full rounded"
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </Container>
  );
}
