import "twin.macro";
import AboutImg from "../images/AboutUs/about-img.png";
import AboutBack from "../images/AboutUs/back.png";
import retailShop from "../images/AboutUs/retailShop.webp";
import silks from "../images/AboutUs/silks.jpg";
import members from "../images/AboutUs/members-banner.jpg";
import { memo } from "react";

function AboutUs({ branch }) {
  return (
    <>
      <div className="container" tw="lg:py-16 py-10 ">
        <div className="row align-items-center flex-column-reverse flex-lg-row py-2">
          <div className="col-md-12 col-lg-6">
            <h1 tw="text-5xl my-5 lg:mt-2">WHO WE ARE!!</h1>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
                {branch.storeName} was established by Mr. P. Arumugam Aasari. He
                began with a gold manufacturing unit in 1980 and founded {branch.storeName}. From a humble beginning with 500 Sq. Ft shop in 1980
                at Shevapet, {branch.storeName} today is recognized as a high
                quality gold manufacturer with an extensive jewellery product
                range to meet the expectations of every customer.
              </p>
              <p tw="text-zinc-300 ">
                Under the vision of our founder Mr. P. Arumugam Aasari, {branch.storeName} has redefined itself from the humble beginnings of a
                500 Sq. Ft Jewellery shop in Shevapet to a world renowned
                fashion destination in 4 Roads, in the name of{" "}
                {branch.storeName}.{branch.storeName} was started its operations
                with a next-generation luxurious showroom in 4 Roads in the year
                2013. Under the able guidance of Mr. A. Manojkumar, {branch.storeName} successfully transformed into the modern and
                innovative jewellery maker that it is today. We are now the
                jewellery destination of choice for every occasion, from a
                woman’s everyday wear to delicately crafted pieces for the
                biggest day of her life. Our stores are staffed with highly
                trained personnel who ensure that every customer leaves a{" "}
                {branch.storeName} completely satisfied.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <img
              src={AboutBack}
              alt="About-Img"
              tw="absolute right-0 top-0 -z-10 hidden lg:block w-5/12"
            />
            <img
              src={AboutImg}
              alt="About-Img"
              className="img-fluid m-auto"
              tw="transform transition duration-500 hover:scale-110"
            />
          </div>
        </div>
        <div className="row py-5 mt-lg-5 align-items-center">
          <div className="col-md-12 col-lg-6">
            <img
              src={retailShop}
              alt="About Our Retail Shop"
              className="w-100"
              tw="rounded-xl"
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <h6 tw="text-5xl my-5 lg:mt-2">Retail showroom</h6>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
                When Mr. P. Arumugam Aasari first started out, his passion gave
                him the impetus to turn hard work and inspiration into a booming
                jewellery store in Salem. Our first retail showroom was opened
                in Gugai in the year 1996 and the journey over the years has
                been inspiring and has garnered the trust of innumerable
                customers.
              </p>
              <p tw="text-zinc-300 ">
                {branch.storeName} draws inspiration from the fact that
                customers are empowered to make right decisions when it comes to
                buying gold. In the year 1996 we have started business 916 gold
                ornament jewellery, with the customer support we have developed
                to the next level.
              </p>
            </div>
            <div className="row align-items-center">
              <div className="col-3">
                <img src={silks} alt={`About Our ${branch.storeName}`} className="rounded" />
              </div>
              <div className="col-9">
                <p tw="text-zinc-300 sm:text-base text-xs">
                  In 2004 we have started a textile showroom in Gugai in the
                  name of {branch.storeName} Silks.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row  mt-lg-5 align-items-center">
          <div className="col-md-12 col-lg-8">
            <img
              src={members}
              alt="retail shop"
              className="w-100"
              tw="rounded-xl"
            />
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="executive my-3 my-md-3">
              <h4>Executive Members of the Board</h4>
              <ul>
                <li>
                  <i
                    className="bi bi-arrow-return-right"
                    tw="text-xl text-custom-100"
                  ></i>{" "}
                  Mr. P. Arumugam Aasari
                </li>
                <li>
                  <i
                    className="bi bi-arrow-return-right"
                    tw="text-xl text-custom-100"
                  ></i>{" "}
                  Mr.P. Manivel Aasari
                </li>
                <li>
                  <i
                    className="bi bi-arrow-return-right"
                    tw="text-xl text-custom-100"
                  ></i>{" "}
                  Mr. P. Ravikumar Aasari
                </li>
                <li>
                  <i
                    className="bi bi-arrow-return-right"
                    tw="text-xl text-custom-100"
                  ></i>{" "}
                  Mr.A. Manojkumar
                </li>
                <li>
                  <i
                    className="bi bi-arrow-return-right"
                    tw="text-xl text-custom-100"
                  ></i>{" "}
                  Mr.M. Ilachelian
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="row align-items-center">
        <div className="col-md-12 col-lg-6">
          <h6 tw="text-5xl my-5 lg:mt-2">Zinger Silks</h6>
          <div className="ptSans text-base">
            <p tw="text-zinc-300 ">
              In 2004 we have started a textile showroom in Gugai in the name of Zinger Silks.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={silks}
            alt="Zinger-retail shop"
            className="w-100"
            tw="rounded-xl"
          />
        </div>
      </div> */}
      </div>
    </>
  );
}
export default memo(AboutUs);
