import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getBearerToken, getHeaderConfig } from "../utility/HeaderConfig";


//CCAVENUE

const getUniqueRequestId = (customerId, chitId, branchId, gatewayName, paymentPurpose , paymentAmount) => {
   axios.defaults.headers.common.token = `Bearer ${ getBearerToken()}`;
  return axios
    .post(`${BASE_URL}/savingsscheme/chitcustomerpayment/get-onlinepayment-key?customerId=${customerId}&chitId=${chitId}&branchId=${branchId}&paymentPurpose=${paymentPurpose}&requestChannel=APP&gateWayName=${gatewayName}&paymentAmount=${paymentAmount}`, getHeaderConfig())
    .then((response) =>  {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};





//PAYG

const getPageOrderStatus = (brachName , OrderId) => {
  return axios
    .get(
      `${BASE_URL}/accounts/onlinePayment/get-payg-order-status?branchName=${brachName}&orderDetails=${OrderId}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const paygOrderStatus = (requestBody: string) => {
    return axios
      .post(`${BASE_URL}/accounts/onlinePayment/create-payg-order`, requestBody, getHeaderConfig())
      .then((response) =>  {
          const respData = {
            responseCode: response.status,
            response: response.data
          };
          return respData;
        })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };


  const paygOrderStatusUpdate = (requestBody: string) => {
    return axios
      .put(`${BASE_URL}/accounts/onlinePayment/update`, requestBody, getHeaderConfig())
      .then((response) =>  {
          const respData = {
            responseCode: response.status,
            response: response.data
          };
          return respData;
        })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };


export {
    paygOrderStatus,
    getPageOrderStatus,
    paygOrderStatusUpdate,
    getUniqueRequestId
};
