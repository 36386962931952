import tw from "twin.macro";
import { motion } from "framer-motion";
import { dateMonthYearFormatUtcToLocal } from "../utility/Constant";
import { useState } from "react";
import PaymentTab from "./PaymentTab.js";
import { onlinePayment } from "../api/MySchemeApi";
import { getBranchId } from "utility/HeaderConfig";

const Heading = tw.h1`text-left font-dancing block text-black  text-2xl mb-0 p-2`;
const TabContent = tw(motion.div)`block`;

const Thead = tw.th`text-xs uppercase tracking-wider`;
const Tbody = tw.td`text-xs sm:text-sm truncate inline-block `;

export default (props) => {
  const [Tnx, setTnx] = useState([]);
  const [Show, setShow] = useState(true);

  const onlineTransaction = () => {
    const response = onlinePayment({
      branchId: getBranchId(),
      productDesc: props.currentChit.id,
    });
    response.then((data) => {
      setShow(false);
      setTnx(data.response.content);
    });
  };

  const details = () => {
    setShow(true);
  };

  console.log(props);

  return (
    <>
      <motion.div
        initial={{ x: "700px", opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        tw="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
      >
        <div tw="relative w-11/12 my-20 mx-auto sm:max-w-3xl md:w-3/4 lg:w-3/4 ">
          {/*content*/}
          <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div tw="flex-wrap sm:flex items-start justify-between items-center p-2 border-b border-solid border-custom-100 rounded-t">
              <div className="col-md-4">
                <Heading>Payment History</Heading>
              </div>
              <div className="py-2 p-sm-0 col-md-6 d-flex justify-content-center">
                <div className="col-md-4 text-center">
                  <button
                    type="button"
                    className="btn position-relative"
                    tw="bg-custom-green text-xs md:text-sm text-white hover:text-white"
                  >
                    Due No : <span>{props.payDetails.totalCount}</span>
                  </button>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    type="button"
                    className="btn position-relative"
                    tw="bg-custom-green text-xs md:text-sm text-white mx-1 hover:text-white"
                  >
                    T.Amt :{" "}
                    <span className="">{props.payDetails.totalAmount}</span>
                  </button>
                </div>
                {props.currentChit &&
                  props.currentChit.chitTypeName !== "INR" && (
                    <div className="col-md-4 text-center">
                      <button
                        type="button"
                        className="btn position-relative"
                        tw="bg-custom-green text-xs  md:text-sm text-white hover:text-white"
                      >
                        Net.Wt :{" "}
                        {props.currentChit &&
                        props.currentChit.chitTypeName !== "INR" ? (
                          <span className="">
                            {props.payDetails.totalNetWeight}
                          </span>
                        ) : (
                          <span className="">-</span>
                        )}
                      </button>
                    </div>
                  )}
              </div>

              <button
                tw="text-right p-1 ml-auto absolute top-0 right-0 sm:relative  border-0 text-gray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                className="col-md-2 p-sm-2 p-3"
                onClick={props.close}
              >
                <i className="bi bi-x-circle"></i>
              </button>
            </div>

            <PaymentTab
              onlineTnx={onlineTransaction}
              paymentHistory={props.close}
              details={details}
              states={Show}
            />

            <TabContent
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "block",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={Show === true ? "current" : "hidden"}
              animate={Show === true ? "current" : "hidden"}
            >
              {props.payDetails.content.map((data, index) => (
                <div
                  className="table-responsive-sm px-2 px-sm-2 px-md-4 col-lg-12 border-bottom"
                  key={index}
                >
                  <table className="table table-borderless my-2 table-sm ">
                    <tr>
                      <Thead scope="row">Paid Date</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {data.paymentDate &&
                          data.paymentDate !== "" &&
                          data.paymentDate !== "-"
                            ? dateMonthYearFormatUtcToLocal(data.paymentDate)
                            : "-"}
                        </span>
                      </Tbody>
                      <Thead scope="row">Rate</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {data.rate
                            ? parseFloat(data.rate).toFixed(2)
                            : parseFloat(0).toFixed(2)}
                        </span>
                      </Tbody>
                    </tr>
                    <tr>
                      <Thead scope="row">Due No</Thead>
                      <Tbody>
                        :<span tw="sm:mx-2">{data.dueNumber}</span>
                      </Tbody>
                      <Thead scope="row">Paid Amt</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {data.paymentAmount && data.paymentAmount !== "-"
                            ? parseFloat(data.paymentAmount).toFixed(2)
                            : "0.00"}
                        </span>
                      </Tbody>
                    </tr>
                    <tr>
                      <Thead scope="row">Receipt No</Thead>
                      <Tbody>
                        : <span tw="sm:mx-2">{data.seqNum}</span>
                      </Tbody>
                      <Thead scope="row">Bonus</Thead>
                      <Tbody>
                        :{" "}
                        {props.currentChit &&
                        props.currentChit.chitTypeName !== "INR" ? (
                          <span tw="sm:mx-2">{data.bonus}</span>
                        ) : (
                          <span tw="sm:mx-2">-</span>
                        )}
                      </Tbody>
                    </tr>

                    <tr>
                      {props.currentChit &&
                      props.currentChit.chitTypeName !== "INR" ? (
                        <>
                          <Thead scope="row">Paid Wt</Thead>
                          <Tbody>
                            :{" "}
                            <span tw="sm:mx-2">
                              {data.paymentGrms && data.paymentGrms !== "-"
                                ? parseFloat(data.paymentGrms).toFixed(3)
                                : "0.000"}
                            </span>
                          </Tbody>
                        </>
                      ) : null}
                      <Thead scope="row">Gift Status</Thead>
                      <Tbody>
                        :{" "}
                        <span
                          tw="sm:mx-2"
                          className={
                            data.chitEnrollmentDetails.giftIssueNumber !== "-"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {data.chitEnrollmentDetails.giftIssueNumber !== "-"
                            ? "Issued"
                            : "Not Issued"}
                        </span>
                      </Tbody>
                    </tr>
                  </table>
                </div>
              ))}
              {props.payDetails.content.length === 0 && (
                <div>
                  <p className="py-3 m-0 text-center text-danger">
                    No Data Found
                  </p>
                </div>
              )}
            </TabContent>

            <TabContent
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "block",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={Show === false ? "current" : "hidden"}
              animate={Show === false ? "current" : "hidden"}
            >
              {Tnx.map((item, index) => (
                <div
                  className="table-responsive-sm px-2 px-sm-2 px-md-4 col-lg-12 border-bottom"
                  key={index}
                >
                  <table className="table table-borderless my-2 table-sm ">
                    <tr>
                      <Thead scope="row">Date</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {item.responseDatetime !== "" &&
                          item.responseDatetime !== "-" &&
                          item.responseDatetime !== null
                            ? dateMonthYearFormatUtcToLocal(
                                item.responseDatetime
                              )
                            : "-"}
                        </span>
                      </Tbody>
                      <Thead scope="row">Order Id</Thead>
                      <Tbody>
                        :{" "}
                        <span
                          tw="sm:mx-2"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={item.orderId}
                        >
                          {item.orderId}
                        </span>
                      </Tbody>
                    </tr>
                    <tr>
                      <Thead scope="row">Txn Amt</Thead>
                      <Tbody>
                        :<span tw="sm:mx-2">{item.authorizedAmount}</span>
                      </Tbody>
                      <Thead scope="row">Txn Type</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {item.txnType ? item.txnType : "-"}
                        </span>
                      </Tbody>
                    </tr>
                    <tr>
                      <Thead scope="row">Txn Id</Thead>
                      <Tbody>
                        :{" "}
                        <span tw="sm:mx-2">
                          {item.txnId ? item.txnId : "-"}
                        </span>
                      </Tbody>
                      <Thead scope="row">Status</Thead>
                      <Tbody>: {item.status ? item.status : "-"}</Tbody>
                    </tr>
                  </table>
                </div>
              ))}
              {Tnx.length === 0 && (
                <div>
                  <p className="py-3 m-0 text-center text-danger">
                    No Data Found
                  </p>
                </div>
              )}
            </TabContent>
          </div>
        </div>
      </motion.div>
      <div tw="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
